var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-in"},[_c('a-row',{staticClass:"row-main",attrs:{"type":"flex","gutter":[24,24],"justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"col-img",attrs:{"span":24,"md":12,"lg":12,"xl":12}},[_c('div',[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":"images/info-rocket-ill.png","alt":"rocket"}})]),_c('h4',{staticClass:"text-white"},[_vm._v("Sua jornada começa aqui")]),_c('p',{staticClass:"text-white"},[_vm._v("Just as it takes a company to sustain a product, it takes a community to sustain a protocol.")])])]),_c('a-col',{staticClass:"col-form mr-auto",attrs:{"span":24,"md":{ span: 14, offset: 2 },"lg":10,"xl":6}},[_c('h4',{staticClass:"mb-15"},[_vm._v("Sign Up")]),_c('p',{staticClass:"text-muted"},[_vm._v("Enter your email and password to register")]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Name","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'name',
					{ rules: [{ required: true, message: 'Please input your name!' }] } ]),expression:"[\n\t\t\t\t\t'name',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your name!' }] },\n\t\t\t\t\t]"}],attrs:{"placeholder":"Name"}})],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Email","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'email',
					{ rules: [{ required: true, message: 'Please input your email!' }] } ]),expression:"[\n\t\t\t\t\t'email',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your email!' }] },\n\t\t\t\t\t]"}],attrs:{"placeholder":"Email"}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'password',
					{ rules: [{ required: true, message: 'Please input your password!' }] } ]),expression:"[\n\t\t\t\t\t'password',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your password!' }] },\n\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Password"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'remember',
						{
							valuePropName: 'checked',
							initialValue: true,
						} ]),expression:"[\n\t\t\t\t\t\t'remember',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tvaluePropName: 'checked',\n\t\t\t\t\t\t\tinitialValue: true,\n\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}]},[_vm._v(" I agree to the "),_c('a',{staticClass:"font-bold text-dark",attrs:{"href":"#"}},[_vm._v("Terms and Conditions")])])],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" SIGN UP ")])],1)],1),_c('p',{staticClass:"font-semibold text-muted text-center"},[_vm._v("Already have an account? "),_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":"/sign-in"}},[_vm._v("Sign Up")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }